.region-title-container {
  position: relative;
  text-align: center;
  margin-bottom: 2rem;
  padding: 1rem 0;
  border-radius: 5px;
  color: #fff;
}

.region-title-container::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center;
  border-radius: 5px;
  z-index: -1;
}

.norte .region-title-container::before {
  background-image: url('../../assets/img/Zona-Norte_Chile.jpg'); /* Imagen de fondo para Norte */
}

.centro .region-title-container::before {
  background-image: url('../../assets/img/Zona-Centro_Chile.jpg'); /* Imagen de fondo para Centro */
}

.sur .region-title-container::before {
  background-image: url('../../assets/img/Zona-Centro_Chile.jpg'); /* Imagen de fondo para Sur */
}

.region-title {
  font-size: 1.5rem;
  font-weight: bold;
  padding: 0.5rem;
  background: rgba(0, 0, 0, 0.5); /* Fondo semi-transparente para mejor visibilidad */
  border-radius: 5px;
}

.loading-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
}

.spinner-border {
  width: 3rem;
  height: 3rem;
  border-width: .4em;
}

.dojo-card {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  box-sizing: border-box;
}

.card-img-top {
  width: 100%;
  height: auto;
}

.card-body {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

@media (max-width: 768px) {
  .region-title {
    font-size: 1.25rem;
  }

  .dojo-card {
    margin-bottom: 1rem;
  }
}


/* Dojos.css */
.region-title-container {
  margin-bottom: 1rem;
}

.region-title {
  font-size: 2rem;
  font-weight: bold;
  text-align: center;
  padding: 0.5rem;
}



.dojo-card {
  transition: transform 0.3s ease-in-out;
}

.dojo-card:hover {
  transform: scale(1.05);
}

.loading-container {
  padding: 2rem;
}

/* Estilo para las tarjetas de dojo */
.dojo-card {
  border-radius: 0.5rem;
  overflow: hidden;
}

.dojo-card-body {
  padding: 1rem;
}

.dojo-card-title {
  font-size: 1.25rem;
  margin-bottom: 0.5rem;
}

.dojo-card-content {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.icon {
  font-size: 1.5rem;
  margin-right: 0.5rem;
  width: 1.5rem;
  height: 1.5rem;
}



.dojo-card-text {
  font-size: 0.875rem;
  margin-bottom: 0.5rem;
}

.dojo-card-img-container {
  flex-shrink: 0;
}

.dojo-card-img {
  height: 100px;
  width: 100px;
  object-fit: cover;
  border-radius: 50%;
}

.dojo-card-button {
  font-size: 0.875rem;
  padding: 0.5rem;
  margin-top: 1rem;
  background-color: #00509e;
  background-attachment: fixed;
}
