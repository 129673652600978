:root {
  --primary-color: #0033a0; /* Azul oscuro de la bandera chilena */
  --secondary-color: #d52b1e; /* Rojo de la bandera chilena */
  --background-color: #ffffff; /* Fondo blanco para las tarjetas de perfil */
  --border-color: #e0e0e0; /* Borde sutil */
  --text-color: #000000; /* Color del texto */
  --shadow-color: rgba(0, 0, 0, 0.2); /* Color de la sombra */
}

.about-section {
  padding: 2rem 1rem;
}

.section-heading {
  font-size: 2.5rem;
  color: var(--primary-color);
  margin-bottom: 2rem;
  text-align: center;
}

.profile-card {
  background-color: var(--background-color);
  border: 1px solid var(--border-color);
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1rem;
  margin-bottom: 2rem;
  text-align: center;
}

@media (min-width: 768px) {
  .profile-card {
    flex-direction: row;
    text-align: left;
  }
}

.profile-image-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 1rem;
}

@media (min-width: 768px) {
  .profile-image-container {
    margin-bottom: 0;
    margin-right: 1rem;
  }
}

.profile-image {
  width: 150px;
  height: 150px;
  object-fit: cover;
  border-radius: 50%;
  border: 2px solid var(--text-color);
}

.profile-info {
  flex: 1;
  padding: 1rem;
}

.card-title-leader {
  background-color: var(--primary-color);
  color: var(--background-color);
  padding: 0.5rem;
  border-radius: 0.25rem;
  margin-bottom: 1rem;
  text-align: center;
}

.btn-primary {
  background-color: var(--secondary-color);
  border-color: var(--secondary-color);
  color: var(--background-color);
}

.btn-primary:hover {
  background-color: darken(var(--secondary-color), 10%);
  border-color: darken(var(--secondary-color), 10%);
}

.shadow {
  box-shadow: 0 2px 8px var(--shadow-color);
}
