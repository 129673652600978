.loading-spinner {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background-color: #f8f9fa; 

  .spinner {
    border: 8px solid #f3f3f3; /* Color de fondo */
    border-top: 8px solid #3498db; /* Color del spinner */
    border-radius: 50%;
    width: 50px;
    height: 50px;
    animation: spin 1s linear infinite;
  }

  p {
    margin-top: 15px;
    font-size: 18px;
    color: #333;
  }

  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
}
