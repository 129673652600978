/* Navbar.css */

.navbar {
  background: linear-gradient(to bottom, #f8f9fa, #e0e0e0); /* Degradado de gris */
  border-bottom: 2px solid #d0d0d0; /* Borde inferior gris claro */
  transition: background 0.3s ease-in-out, box-shadow 0.3s ease-in-out, top 0.3s ease-in-out;
  position: relative;
  will-change: transform; /* Mejora el rendimiento de la transición */
}

.navbar.sticky {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1000;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Sombra para darle un efecto flotante */
  transition: transform 0.3s ease-in-out; /* Transición más natural */
  transform: translateY(0); /* Asegura que la posición sea correcta */
}

.navbar-hidden {
  transform: translateY(-100%);
  transition: transform 0.3s ease-in-out; /* Transición más natural */
}

.navbar-brand {
  font-size: 1.5rem;
  font-weight: bold;
  color: #00509e; /* Azul suave */
  transition: color 0.3s ease-in-out;
}

.navbar-brand:hover {
  color: #003f7d; /* Azul un poco más oscuro */
}

.navbar-toggler {
  border: none;
  transition: all 0.3s ease-in-out;
}

.navbar-toggler-icon {
  filter: brightness(0.5);
  transition: all 0.3s ease-in-out;
}

.navbar-nav .nav-link {
  font-size: 1.125rem;
  color: #00509e; /* Azul suave */
  transition: color 0.3s ease-in-out;
  position: relative;
  padding: 0.75rem 1rem;
}

.navbar-nav .nav-link::after {
  content: "";
  position: absolute;
  width: 0;
  height: 3.5px;
  bottom: 2px;
  left: 50%;
  right: 50%;
  background-color: #ac3636; /* Rojo suave */
  transition: all 0.3s ease-in-out;
}

.navbar-nav .nav-link:hover::after,
.navbar-nav .nav-link.active::after {
  width: 100%;
  left: 0;
}

.navbar-nav .nav-link:hover {
  color: #ff6b6b; /* Rojo suave */
}

.navbar-nav .nav-item {
  margin-left: 1rem;
}

@media (max-width: 991px) {
  .navbar-nav {
    margin-top: 1rem;
  }

  .navbar-nav .nav-item {
    margin-left: 0;
    margin-bottom: 0.5rem;
  }
}

/* Logo Karate */
.karate-logo {
  height: 50px; /* Tamaño del logo */
}
