/* Cards.css */
.card-custom {
  transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
}

.card-custom:hover {
  transform: translateY(-10px);
  box-shadow: 0 12px 24px rgba(0, 0, 0, 0.15);
}

.card-title {
  font-weight: 700;
  font-size: 1.25rem;
  color: #333;
}

.card-text {
  color: #555;
}

.btn-primary {
  background-color: #007bff;
  border-color: #007bff;
  transition: background-color 0.3s ease-in-out, border-color 0.3s ease-in-out;
}

.btn-primary:hover {
  background-color: #0056b3;
  border-color: #004085;
}

.card-img-top {
  cursor: pointer;
  transition: opacity 0.3s ease-in-out;
}

.card-img-top:hover {
  opacity: 0.85;
}

.card-image-container {
  position: relative;
  overflow: hidden;
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}

.card-icon-overlay {
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 10;
  cursor: pointer;
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 50%;
  padding: 5px;
}

.alert-warning {
  background-color: #ffc107;
  color: #856404;
}
