/* Contenedor principal del detalle de la noticia */
.container-Ndetails {
  max-width: 100%;
  margin-top: 2rem;
  margin-bottom: 2rem;
  padding: 0 1rem;
}

/* Estilo general para el post */
.post-details {
  background-color: #ffffff;
  border-radius: 0.75rem;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  margin-bottom: 2rem;
}

/* Imagen del post */
.post-details .card-img-top {
  width: 100%;
  height: auto;
  max-height: 500px;
  object-fit: cover;
  border-bottom: 1px solid #e0e0e0;
}

/* Cuerpo del post */
.post-details .card-body {
  padding: 2rem;
}

/* Título del post */
.post-details .card-title {
  font-size: 2.5rem;
  font-weight: bold;
  margin-bottom: 1rem;
  color: #222;
}

/* Metadatos del post */
.blog-meta {
  font-size: 0.95rem;
  color: #666;
  margin-bottom: 1.5rem;
}

.blog-meta span {
  margin-right: 1rem;
}

/* Contenido del post */
.post-content {
  margin-top: 1rem;
  line-height: 1.75;
  color: #444;
}

/* Estilo para las categorías */
.blog-tags {
  margin-top: 2rem;
}

.blog-tags h4 {
  font-size: 1.25rem;
  margin-bottom: 0.5rem;
  color: #333;
}

.blog-tags ul {
  list-style: none;
  padding: 0;
}

.blog-tags li {
  display: inline-block;
  margin-right: 0.75rem;
  margin-bottom: 0.75rem;
}

.blog-tags a {
  color: #007bff;
  text-decoration: none;
  font-weight: bold;
  border: 1px solid #007bff;
  padding: 0.25rem 0.5rem;
  border-radius: 0.25rem;
  transition: background-color 0.3s, color 0.3s;
}

.blog-tags a:hover {
  background-color: #007bff;
  color: #fff;
}

/* Tarjetas de contenido relacionado */
.related-posts {
  margin-top: 2rem;
}

.related-posts h2 {
  font-size: 1.75rem;
  font-weight: bold;
  margin-bottom: 1rem;
  color: #333;
  border-bottom: 2px solid #e0e0e0;
  padding-bottom: 0.5rem;
}

.related-posts .related-post-card {
  flex: 1 1 calc(33.333% - 1rem);
  max-width: calc(33.333% - 1rem);
  margin-bottom: 1rem;
  border: none;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  border-radius: 0.5rem;
  overflow: hidden;
}

.related-posts .related-post-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.1);
}

.related-posts .related-post-thumbnail {
  width: 100%;
  height: 200px;
  object-fit: cover;
}

.related-posts .related-post-card-body {
  padding: 1rem;
  background-color: #f8f9fa;
  border-top: 1px solid #e0e0e0;
}

.related-posts .related-post-card-title {
  font-size: 1rem;
  font-weight: 500;
  color: #333;
  margin: 0;
}

/* Estilo para la barra lateral */
.sidebar {
  position: -webkit-sticky;
  position: sticky;
  top: 1rem;
}

/* Estilo para los widgets de la barra lateral */
.widget {
  margin-bottom: 1.5rem;
}

.widget .card-title {
  font-size: 1.5rem;
  font-weight: bold;
  margin-bottom: 1rem;
  color: #333;
}

/* Estilo para los enlaces sociales */
.social-icon {
  font-size: 1.5rem;
  color: #333;
  transition: color 0.3s;
}

.social-icon:hover {
  color: #007bff;
}

/* Responsive design */

/* Para dispositivos móviles */
@media (max-width: 576px) {
  .container-Ndetails {
    padding: 0 0.5rem;
  }

  .post-details .card-body {
    padding: 1rem;
  }

  .post-details .card-title {
    font-size: 2rem;
  }

  .blog-meta {
    font-size: 0.85rem;
  }

  .post-content {
    font-size: 0.9rem;
  }

  .blog-tags h4 {
    font-size: 1rem;
  }

  .related-posts h2 {
    font-size: 1.25rem;
  }

  .related-posts .related-post-card {
    flex: 1 1 100%;
    max-width: 100%;
  }

  .sidebar {
    position: static;
    margin-top: 2rem;
  }

  .social-icon {
    font-size: 1.25rem;
  }
}

/* Para tabletas (de 577px a 768px) */
@media (min-width: 577px) and (max-width: 768px) {
  .container-Ndetails {
    padding: 0 1rem;
  }

  .post-details .card-body {
    padding: 1.5rem;
  }

  .post-details .card-title {
    font-size: 2.25rem;
  }

  .blog-meta {
    font-size: 0.9rem;
  }

  .post-content {
    font-size: 1rem;
  }

  .blog-tags h4 {
    font-size: 1.1rem;
  }

  .related-posts h2 {
    font-size: 1.5rem;
  }

  .related-posts .related-post-card {
    flex: 1 1 calc(50% - 1rem);
    max-width: calc(50% - 1rem);
  }
}

/* Para pantallas grandes (de 769px en adelante) */
@media (min-width: 769px) {
  .related-posts .related-post-card {
    flex: 1 1 calc(33.333% - 1rem);
    max-width: calc(33.333% - 1rem);
  }
}
