/* Contenedor principal de la galería */
.gallery-container {
  background-color: #f8f9fa;
  padding: 2rem;
  border-radius: 0.75rem;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

/* Título de la galería */
.gallery-title {
  color: #0033a0; /* Azul de la bandera chilena */
  font-weight: 700;
  margin-bottom: 1rem;
  text-align: center;
  font-size: 2rem;
}

/* Tarjeta de la galería */
.gallery-card {
  border: 1px solid #ddd;
  border-radius: 0.75rem;
  overflow: hidden;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  position: relative;
  margin-bottom: 1.5rem;
}

/* Efecto al pasar el ratón sobre la tarjeta */
.gallery-card:hover {
  transform: scale(1.05);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
}

/* Imagen o video en la tarjeta */
.gallery-media {
  border-bottom: 1px solid #d60303;
  border-top-left-radius: 0.75rem;
  border-top-right-radius: 0.75rem;
  width: 100%;
  height: auto;
  object-fit: cover;
}

/* Título dentro de la tarjeta */
.gallery-card-title {
  font-size: 1.5rem;
  color: #0033a0;
  margin: 0.75rem;
  text-align: center;
  font-weight: 600;
}

/* Descripción dentro de la tarjeta */
.gallery-card-description {
  color: #666;
  text-align: center;
  margin-bottom: 1rem;
  padding: 0 1rem;
}

/* Estilos para el modal */
.modal-title {
  font-weight: 700;
  color: #0033a0;
  align-items: center;
  text-align: center;
  /* centrar text */
  text-shadow: #d60303;
}

.modal-description-title {
  font-weight: 600;
  color: #0033a0;
  margin-bottom: 0.5rem;
  text-align: center;
}

.modal-description-text {
  color: #333; /* Color oscuro para una mejor legibilidad */
  font-size: 1rem; /* Tamaño de fuente adecuado para la lectura */
  line-height: 1.6; /* Espaciado entre líneas para mayor claridad */
  margin-bottom: 1rem; /* Espacio debajo del texto */
  padding: 0.5rem 1rem; /* Espaciado interno para evitar que el texto toque los bordes */
  background-color: #f9f9f9; /* Fondo gris claro para contraste suave con el texto */
  border-radius: 0.5rem; /* Bordes redondeados para un aspecto más suave */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Sombra ligera para mejorar el contraste con el fondo */
}

/* Imagen o video dentro del modal */
.modal-media {
  border-radius: 0.75rem;
  width: 100%;
  height: auto;
  object-fit: cover;
}



/* Botón de pantalla completa */
.fullscreen-btn {
  position: absolute;
  bottom: 10px;
  left: 50%;
  transform: translateX(-50%);
  background-color: #e4002b; /* Rojo de la bandera chilena */
  color: white;
  border: none;
  border-radius: 0.5rem;
  padding: 0.5rem 1rem;
  font-size: 0.875rem;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.3s ease;
  z-index: 1; /* Asegura que esté por encima del video */
}

/* Efecto al pasar el ratón sobre el botón */
.fullscreen-btn:hover {
  background-color: #c70039;
  transform: scale(1.05);
}

/* Contenedor del video dentro del modal */
.video-wrapper {
  position: relative;
  width: 100%;
  height: auto;
}

/* Media Queries para Responsividad */
@media (max-width: 1200px) {
  .gallery-container {
    padding: 1.5rem;
  }
}

@media (max-width: 992px) {
  .gallery-card {
    margin-bottom: 1rem;
  }

  .gallery-card-title {
    font-size: 1.25rem;
  }

  .gallery-card-description {
    font-size: 0.875rem;
  }
}

@media (max-width: 768px) {
  .gallery-container {
    padding: 1rem;
  }

  .gallery-card-title {
    font-size: 1rem;
  }

  .gallery-card-description {
    font-size: 0.75rem;
  }

  .fullscreen-btn {
    font-size: 0.75rem;
    padding: 0.5rem;
  }
}

@media (max-width: 576px) {
  .gallery-title {
    font-size: 1.5rem;
  }

  .gallery-card-title {
    font-size: 0.875rem;
  }

  .gallery-card-description {
    font-size: 0.65rem;
  }

  .fullscreen-btn {
    font-size: 0.625rem;
    padding: 0.5rem;
  }
}
