/* Tree.css */

.tree-container {
  background-color: #ffffff;
  border: 1px solid #dee2e6;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  border-radius: 0.375rem;
}

.tree-content .tree-node {
  padding: 1rem;
}

.tree-content .tree-node:last-child {
  margin-bottom: 0;
}

.text-dark {
  color: #343a40;
}

.text-muted {
  color: #6c757d;
}
