/* Categories.css */

.category-controls {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 1rem;
  margin-bottom: 1.5rem;
}

.category-link {
  font-size: 1.125rem;
  padding: 0.5rem 1rem;
  border-radius: 0.375rem;
  transition: all 0.3s ease-in-out;
  text-decoration: none;
  color: #495057;
  background-color: #f8f9fa;
  border: 1px solid #dee2e6;
}

.category-link:hover {
  background-color: #e9ecef;
  color: #343a40;
  transform: scale(1.05);
}

.active-category {
  background-color: #007bff;
  color: #fff !important;
  border-color: #007bff;
}

.selected-category {
  font-weight: bold;
}
