.not-found-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  text-align: center;
  padding: 20px;
  background-color: #f5f5f5;
  margin: 0;
  box-sizing: border-box;
}

.logo-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30vw;
  height: 30vw;
  max-width: 250px;
  max-height: 250px;
  background: linear-gradient(to right, #0033A0 50%, #FFFFFF 50%);
  border-radius: 50%;
  margin-bottom: 30px;
  border: 10px solid #D52B1E;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.3);
}

.not-found-logo {
  width: 80%;
  height: auto;
  border-radius: 50%;
}

.not-found-title {
  font-family: 'Arial', sans-serif;
  font-size: 6rem;
  font-weight: bold;
  margin: 10px 0;
  color: #D52B1E;
  text-shadow: 3px 3px 6px rgba(0, 0, 0, 0.3);
}

.not-found-subtitle {
  font-family: 'Arial', sans-serif;
  font-size: 2rem;
  margin: 10px 0;
  color: #0033A0;
}

.not-found-buttons {
  margin-top: 30px;
}

.not-found-button {
  display: inline-block;
  margin: 10px;
  padding: 15px 30px;
  font-size: 1.25rem;
  color: #fff;
  background-color: #D52B1E;
  text-decoration: none;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.2s ease;
}

.not-found-button:hover {
  background-color: #a20c0d;
  transform: scale(1.05);
}

.not-found-button:focus {
  outline: none;
  box-shadow: 0 0 0 2px rgba(0, 123, 255, 0.5);
}

/* Responsividad */
@media (max-width: 768px) {
  .not-found-title {
    font-size: 4rem;
  }

  .not-found-subtitle {
    font-size: 1.5rem;
  }

  .logo-container {
    width: 40vw;
    height: 40vw;
  }

  .not-found-button {
    font-size: 1rem;
    padding: 10px 20px;
  }
}

@media (max-width: 480px) {
  .not-found-title {
    font-size: 3rem;
  }

  .not-found-subtitle {
    font-size: 1.25rem;
  }

  .logo-container {
    width: 50vw;
    height: 50vw;
  }

  .not-found-button {
    font-size: 0.875rem;
    padding: 8px 16px;
  }
}
