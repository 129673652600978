/* Notices Section */
.notices-section {
  padding: 15px;
  max-width: 1000px;
  margin: 0 auto;
}

.section-title {
  font-size: 1.5rem;
  margin-bottom: 15px;
  text-align: center;
  color: #333;
}

.search-container {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
}

.search-container input[type="text"] {
  flex: 1;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 0.9rem;
}

.search-icon {
  margin-left: -25px;
  color: #007bff;
  font-size: 1rem;
}

.noticias-list {
  display: grid;
  gap: 15px;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
}

.pagination {
  display: flex;
  justify-content: center;
  margin-top: 15px;
}

.pagination-button {
  background: #007bff;
  color: white;
  border: none;
  padding: 8px 12px;
  margin: 0 3px;
  cursor: pointer;
  border-radius: 4px;
  font-size: 0.9rem;
}

.pagination-button.active {
  background: #0056b3;
}

.pagination-button:disabled {
  background: #ddd;
  cursor: not-allowed;
}

.pagination-button:hover:not(:disabled) {
  background: #0056b3;
}

@media (max-width: 768px) {
  .search-container input[type="text"] {
    font-size: 0.8rem;
  }
  
  .pagination-button {
    font-size: 0.8rem;
    padding: 6px 10px;
  }
}

/* Cards Notices */
.noticia {
  border: 1px solid #d0d0d0;
  border-radius: 8px;
  overflow: hidden;
  margin-bottom: 15px;
  background-color: #f9f9f9;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s, box-shadow 0.3s;
}

.noticia:hover {
  transform: translateY(-3px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
}

.noticia-image {
  width: 100%;
  height: 180px;
  object-fit: cover;
  border-bottom: 1px solid #d0d0d0;
}

.noticia-content {
  padding: 15px;
}

.noticia-title {
  font-size: 1.4rem;
  margin: 0 0 8px;
  color: #333;
  font-weight: 600;
}

.noticia-category,
.noticia-date {
  color: #666;
  font-size: 0.8rem;
  margin: 0;
}

.noticia-description {
  margin: 10px 0;
  font-size: 0.9rem;
  color: #444;
}

.noticia-link {
  display: inline-block;
  margin-top: 8px;
  color: #007bff;
  font-weight: bold;
  text-decoration: none;
  border: 1px solid #007bff;
  padding: 6px 12px;
  border-radius: 4px;
  transition: background-color 0.3s, color 0.3s;
}

.noticia-link:hover {
  background-color: #007bff;
  color: #ffffff;
}
